import React from "react"
import Layout from "../../components/layout"
import { Link } from "gatsby"
import SEO from "../../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import dowDoc from "../../images/download-doc.png"
import felisw2detail from "../../images/Felis-W2.webp"
import felisw2Doc from '../../docs/Felis-W2.pdf'

class Felisw2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isToggleOn: true };
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    this.setState(state => ({
      isToggleOn: !state.isToggleOn
    }));
  }

  render() {
    return <Layout>
      <SEO title="Felis-W2 | Tumble Washer Controller" 
      description="Proalcs's control panel for industrial washing machines designed for heavy industrial conditions. "/>
      <Container>
        <div className='prolacs-divider-title' style={{ marginTop: '0', backgroundColor: '#FFFFFF', display: 'flex', alignItems: 'baseline' }}>
          <br></br>
          <br></br>
          <Link className='link-breadcrumb' to='/products'><h2 style={{ fontSize: '19px' }}>Products /</h2> </Link>
          <h1 style={{ fontSize: '20px', paddingLeft: '5px' }}> Felis-W2 </h1>
        </div>
        <Row>
          <Col sm className='product-detail-head'>
            <div className={`product-detail-header-image´  ${this.state.isToggleOn ? 'product-detail-header-image' : 'product-detail-header-image-zoom'}`} >
              <img className='product-detail' onClick={this.handleClick} src={felisw2detail} alt="felis-w2-prolacs" />
            </div>
          </Col>
          <Col sm className='product-detail-title'>
            <div>
              <h1 className='controllerheadtext' style={{width:"100%"}}>Felis-W2</h1>
            </div>
            <h2> Tumble Washer Controller</h2>
            <p>It is a control panel for industrial washing machines designed for heavy industrial conditions. Resistant to vibration and dust. It has a total capacity of 40 programs including 16 default and 24 user defined programs. It is an easy-to-use, standard serial controller thanks to the Custom Design LCD display and membrane keypad supported by icons. The design of the control panel is customized according to the needs and designed in accordance with the brand according to customer demands.</p>
          </Col>

        </Row>
        <Row>
         
          <Col sm className='product-detail-title'>
            <div >
              <h1 className='controllerheadtext' style={{width:"100%"}}>Documents</h1>
            </div>
            <br></br>
            <Row>
              <Col className='product-detail-doc-name'>
              <a  target="_blank" rel="noopener noreferrer"  href={felisw2Doc} >  <img className='product-detail-doc' src={dowDoc} alt="prolacs-felis-w2-document" />
              <h1>Felis-W2</h1>  </a>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className='prolacs-divider-title'>
          <br></br>
          <br></br>
          <h1 >Specifications</h1>
        </div>
        <Row className='lms-inside-content'>
          <Col sm>
            <li>Quick start-up with connector card </li>
            <li>On board level sensor allows water level control (No need external sensor)</li>
            <li>Flexible user program support, diagnostic control and large parameter list. </li>
            <li>Quick access to frequently used programs with 5 Memory keys.</li>
            <li>Suitable for heating / steam heating.</li>
            <li>Suitable for Coin Operated. (Optional)</li>
            <li>Tilting support. (Optional)</li>
            <li>Hygiene barrier support. (Optional)</li>
            <li>AC-Drive and balance detection control via Modbus RTU. (Optional</li>
          </Col>
        </Row>

        <div className='prolacs-divider-title'>
          <br></br>
          <br></br>
          <h1 >Features</h1>
        </div>
        <div >
          <h1 className='controllerheadtext' style={{width:"100%"}}>Inputs/Outputs</h1>
        </div>

        <Row className="product-table-row">
          <Col>
            <p>Analog Input</p>
          </Col>
          <Col>
            <p>
              Onboard Level Sensor (Hydrostatic 0-100 cm)
          <br />
              Temperature Sensor Input (-10~100 °C)
        </p>
          </Col>
          <Col className="lastp">
            <p>
              1<br />1
        </p>
          </Col>
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Analog Output</p>
          </Col>
          <Col>
            <p>AC Drive Control 0~10V DC</p>
          </Col>
          <Col className="lastp">
            <p>1</p>
          </Col>
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Digital Input</p>
          </Col>
          <Col>
            <p>5-24V DC </p>
          </Col>
          <Col className="lastp">
            <p>8</p>
          </Col>
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Counter Input</p>
          </Col>
          <Col>
            <p>5-24 V DC 1 KHz</p>
          </Col>
          <Col className="lastp">
            <p>1</p>
          </Col>
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Digital Output</p>
          </Col>
          <Col>
            <p> 24V DC 1A Relay NO </p>
            <p> 250V AC 1A Relay NO</p>
            <p> 24V AC/DC 1A Relay NO (Optional)</p>
          </Col>
          <Col className="lastp">
            <p>16</p>
            <p>2</p>
            <p>6+6</p>
          </Col>
        </Row>

        <div >
          <h1 className='controllerheadtext' style={{width:"100%"}}>Programming</h1>
        </div>
        <Row className="product-table-row">
          <Col>
            <p>Program Count</p>
          </Col>
          <Col>
            <p>
              16 Default Programs <br />
              24 User Programs
        </p>
          </Col>
          <Col className="lastp">
            <p>40</p>
          </Col>
        </Row>
        <div>
          <h1 className='controllerheadtext' style={{width:"100%"}}>Technical Specifications</h1>
        </div>
        <Row className="product-table-row">
          <Col>
            <p>Operating Voltage</p>
          </Col>
          <Col>
            <p>12 V AC/DC</p>
          </Col>
          <Col className="lastp" />
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Power Consumption</p>
          </Col>
          <Col>
            <p>Max 4W</p>
          </Col>
          <Col className="lastp" />
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Operating Temperature</p>
          </Col>
          <Col>
            <p>0~60 °C</p>
          </Col>
          <Col className="lastp" />
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Operating Humidty</p>
          </Col>
          <Col>
            <p>%10 - 85 (Non-Condensing)</p>
          </Col>
          <Col className="lastp" />
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Isolation </p>
          </Col>
          <Col>
            <p>2000 V Digital</p>
          </Col>
          <Col className="lastp" />
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Keyboard </p>
          </Col>
          <Col>
            <p>16 Keys</p>
          </Col>
          <Col className="lastp" />
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Display </p>
          </Col>
          <Col>
            <p>130x62 mm Custom Designed LCD</p>
          </Col>
          <Col className="lastp" />
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Dimensions</p>
          </Col>
          <Col>
            <p>130x256x35 mm</p>
          </Col>
          <Col className="lastp" />
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Processor</p>
          </Col>
          <Col>
            <p>Micro Processor</p>
          </Col>
          <Col className="lastp" />
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Communication Port</p>
          </Col>
          <Col>
            <p>RS 485 (Optional)</p>
          </Col>
          <Col className="lastp" />
        </Row>

      </Container >
    </Layout >
  }
};
export default Felisw2
